import React from "react";

const Pricing = ({ className, ...rest }) => {

  return (
    <div className={className} {...rest}>
      <div className="container">

      <div className="row justify-content-center text-left">
            <div className="col-xl-5 bg-white rounded-10">
                <h5 className="pt-10 pl-10">Products</h5>
                <ul className="list-unstyled font-size-5 text-dark-cloud pt-10 pb-5 pl-10 pr-20">
                <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    Upselling
                  </li>
                  <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    How to’s and demonstrations
                  </li> 
                  <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    eCommerce and retail
                  </li>                                
                  <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    Projects and Case studies
                  </li>
                  <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    Video editorials and PR
                  </li>  
                  <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    Influencers
                  </li>   
                  <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    Showcases
                  </li>   
                  <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    Unboxing / walk-throughs
                  </li>                                                                        
                                                      
                </ul>                      
              </div>
            <div className="col-xl-1"></div>
            <div className="col-xl-5 bg-white rounded-10">
              <h5 className="pt-10 pl-10">Educational</h5>
              <ul className="list-unstyled font-size-5 text-dark-cloud pt-10 pb-5 pl-10 pr-20">
              <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  Webinars
                </li>
                <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  eLearning and teaching
                </li> 
                <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  Tutorials
                </li>                                
                <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  Online classes
                </li>
                <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  Onboarding
                </li>   
                <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  Advertising
                </li>   
                <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  Digital storytelling
                </li>  
                <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  Video CVs and portfolios
                </li>                                                                                        
                                                     
              </ul>                      
            </div>            
          </div>

          <div className="row justify-content-center text-left mt-10">
            <div className="col-xl-5 bg-white rounded-10">
                <h5 className="pt-10 pl-10">Industries</h5>
                <ul className="list-unstyled font-size-5 text-dark-cloud pt-10 pb-5 pl-10 pr-20">
                <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    Construction
                  </li>
                  <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    Interior design
                  </li> 
                  <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    Property, sales and lettings
                  </li>                                
                  <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    Sport, leisure and tourism
                  </li>
                  <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    Hospitality
                  </li>  
                  <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    Fashion and retail
                  </li>     
                  <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    Technology
                  </li>   
                  <li className="mb-6">
                    <i className="fa fa-check mr-4 text-white" />
                    and many more…
                  </li>                                                                                        
                                                      
                </ul>                      
              </div>
            <div className="col-xl-1"></div>
            <div className="col-xl-5 bg-white rounded-10">
              <h5 className="pt-10 pl-10">Hobbies &amp; Business</h5>
              <ul className="list-unstyled font-size-5 text-dark-cloud pt-10 pb-5 pl-10 pr-20">
              <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  Blogging and Vlogging
                </li>
                <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  Comparison and unboxing
                </li> 
                <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  Influential
                </li>                                
                <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  DIY
                </li>
                <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  Cooking
                </li>   
                <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  Cleaning
                </li>   
                <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  General home economics
                </li>  
                <li className="mb-6">
                  <i className="fa fa-check mr-4 text-white" />
                  Explainer
                </li>                                                                                        
                                                     
              </ul>                      
            </div>            
          </div>

        {/* End Pricing Table */}
      </div>
    </div>
  );
};

export default Pricing;
