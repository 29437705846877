import React from "react";
import Industry from "../../sections/player/industry";

const Problem = ({ className, ...rest }) => {
  return (
    <div
    className={className}
    {...rest} style={{position: "relative", top:"-15px", zIndex:"100"}}>
      <div className="container pb-20 text-center">
        <h5 className="pt-10 font-size-9">Applications</h5>
        <h6 className=" font-size-7">Create custom content for any video application or industry.</h6>        
        <Industry className="pt-10 mb-0" />   


      </div>

    </div>  
  );
};

export default Problem;


