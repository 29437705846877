import React from "react";
import {Link} from "gatsby";
import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/features/Hero";
import CTA from "../../sections/CTA/CTA";
import Applications from "../../sections/player/Applications";
import Tracking from "../../sections/player/Tracking";
import Company from "../../sections/home1/Company";
import SEO from '../../components/SEO';
import metaImg from '../../assets/image/metaimg/features.jpg';
import WaveReverse from "../../sections/common/WaveReverse";

const FeaturesPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,
          headerButton: (
            <>
              <Link to="/contact" className="btn btn btn-dodger-blue-2 btn-medium rounded-5 font-size-3">
                Let's Get Started
              </Link>
            </>
          ),
          footerStyle: "digma",
        }}>
        <SEO
          title="Pioneering Features make Digma's Player Unique"
          description="Digma will make your videos incomparable with an interactive player that is feature rich."
          image={metaImg}
        />             
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info/">Info</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>              
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info/features">Features</Link>
              </li>
              </ol>
           </nav>
        </div>            
        <Hero />          
        <div id="tracking">
        <WaveReverse pos="center" background="#FFF" color="#F7F9FC" />  
        <Tracking className="grey" />        
        </div>  
        <div id="applications">
        <WaveReverse pos="large" background="#F7F9FC" color="#fff" />  
          <Applications  />        
        </div>            
        <WaveReverse background="#FFF" color="#F7F9FC" />      
        <Company className="grey pt-13 pt-md-17 pt-lg-13 pb-13 pb-md-14 pb-lg-23" />            
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default FeaturesPage;
