import React from "react";
import Reports from "../../sections/player/reports";

const Problem = ({ className, ...rest }) => {
  return (
    <div
    className={className}
    {...rest} style={{position: "relative", top:"-5px", zIndex:"100"}}>
      <div className="container pb-20 text-center">
        <h5 className="pt-10 font-size-9">Tracking &amp; Reporting</h5>
        <h6 className=" font-size-7">Track and Report with Digma and Google Tag Manager</h6>        
        <Reports className="pt-10 mb-0" />   
      </div>

    </div>  
  );
};

export default Problem;


