import React from "react";

const Problem = ({ className, ...rest }) => {
  return (
    <div
    className={className}
    {...rest} style={{position: "relative", top:"-5px", zIndex:"100"}}>
      <div className="container pb-20 pt-0 text-center">
        <div className="container mt-0">
          <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-6">
                  <div className="row justify-content-center">
                    {/* Single table */}
                    <div className="col-xl-12 col-md-12 col-sm-10 col-xs-10">
                      <div
                        className="grey rounded-10 pl-9 pl-xs-12 pr-10 pr-xs-15 pt-7 pt-xs-12 pb-9 pb-xs-13 mb-13 mb-lg-0"
                        data-aos="fade-up"
                        data-aos-delay={500}
                        data-aos-once="true"
                      >
                        <h6 className="font-size-7 text-digma-dark text-left">Via Digma</h6>
                        <ul className="text-left list-style-none">
                        <li className="mb-5">
                            <strong className="">Tracking</strong>
                            <p>At Digma, we track every video and interaction made by your website users, so you can analyse your user behaviour</p>
                          </li>
                          <li className="mb-5">
                            <strong className="">Reporting</strong>
                            <p>Whatever campaign you are running, we offer end of month reports which will show all of your video statistics and most popular interactions</p>
                          </li>
                          <li className="mb-5">
                            <strong className="">Assistance</strong>
                            <p>If you require assistance understanding your statistics, we are only a phone call away!</p>
                          </li>
                        </ul>   
                      </div>
                    </div>
                    {/* End Single table */}
                  </div>
            </div>
          <div className="col-xl-6 col-lg-6">
                  <div className="row justify-content-center">
                    {/* Single table */}
                    <div className="col-xl-12 col-md-12 col-sm-10 col-xs-10">
                      <div
                        className="grey rounded-10 pl-9 pl-xs-12 pr-10 pr-xs-15 pt-7 pt-xs-12 pb-9 pb-xs-13 mb-13 mb-lg-0"
                        data-aos="fade-up"
                        data-aos-delay={500}
                        data-aos-once="true"
                      >
                        <h6 className="font-size-7 text-digma-dark text-left">Via Google Tag Manager</h6>
                        <ul className="text-left list-style-none">
                        <li className="mb-5">
                            <strong className="">Tracking</strong>
                            <p>Interactive videos can be seamlessly tracked using Google Tag Manager but adding specific variables, triggers and tags to your account</p>
                          </li>
                          <li className="mb-5">
                            <strong className="">Self Reporting</strong>
                            <p>Statistics automatically show within your Events section, in your Google Analytics account</p>
                          </li>
                          <li className="mb-5">
                            <strong className="">Assistance</strong>
                            <p>If you are struggling to set up Google tracking, please contact us for FREE expert help and advice</p>
                          </li>
                        </ul>              
                        </div>
                    </div>
                    {/* End Single table */}
                  </div>
            </div>

          </div>
          {/* End Pricing Table */}
          </div>
      </div>

    </div>  
  );
};

export default Problem;


